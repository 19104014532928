/*
* Mate - Bluma Fungry Template Responsive css

/*
    small-mobile: from to 360px
    mobile: up to 768px
    tablet: from 769px
    desktop: from 1024px
    widescreen: from 1216px
    fullhd: from 1408px
*/

/* On screens that are 1408px wide or less */

@media screen and (min-width: 2304px) {
  .as-header-text {
    position: relative;
    left: 20% !important;
    top: 30% !important;
  }
}

@media screen and (min-width: 1830px) {
  .as-header-text {
    position: relative;
    left: 20% !important;
    top: 30% !important;
  }
  .as-stores {
    margin: 0px auto auto 100px !important;
  }
}

@media screen and (min-width: 1800px) {
  .as-rounded-img2 {
    position: absolute;
    top: 40% !important;
    margin-top: 70px;
  }
  .as-rounded-img {
    position: absolute;
    top: 50% !important;
  }
}

@media only screen and (min-width: 1600px) {
  .as-rounded-img2 {
    top: 85% !important;
    right: 40% !important;
  }
  .as-header-svg {
    min-height: 1100px;
  }
  .as-rounded-img {
    position: absolute;
    top: 80% !important;
  }
}

@media screen and (min-width: 1400px) {
  .as-header-text {
    position: relative;
    left: 10%;
    top: 25%;
  }
  .as-header3 {
    min-height: 800px;
    background-size: cover;
  }
  .as-rounded-img {
    top: 40% !important;
  }
  .as-calm {
    margin-top: 600px !important;
  }
}

@media screen and (min-width: 1340px) {
  .as-header-text {
    position: relative;
    left: 10%;
    top: 35%;
  }
}

@media screen and (max-width: 1339px) {
  .as-header-text {
    padding: 0px 200px 0 400px !important;
    top: 30%;
    left: 10%;
  }
  .as-header3 {
    min-height: 500px;
    background-size: cover;
  }
}

@media screen and (max-width: 1199px) {
  .as-rounded-img2 {
    top: 25% !important;
  }
  .as-rounded-img {
    top: 28% !important;
  }
}

@media screen and (max-width: 1360px) {
  .as-benefit {
    margin-top: 200px !important;
  }
  .as-header-text {
    position: relative;

    top: 45%;
  }
}

/* On screens that are 1216px wide or less */
@media screen and (max-width: 1130px) {
  .as-left {
    left: 5%;
  }
  .as-right {
    right: 5%;
  }
  .as-padding-right {
    padding-right: 100px;
  }
}

@media screen and (max-width: 1070px) {
  .as-circle-text {
    text-align: right !important;
  }
  .as-padding-right {
    padding-right: 0;
  }
  .as-circle-text h1,
  .as-circle-text h6 {
    padding-left: 30px;
  }
  .as-circle-text {
    text-align: right !important;
  }
  .as-circle-text h1,
  .as-circle-text h6 {
    text-align: right !important;
  }

  .as-rounded-img2 {
    top: 22% !important;
  }
  .as-rounded-img {
    top: 35% !important;
  }
}

/* On screens that are 1024px wide or less */
@media screen and (max-width: 1054px) {
  .as-circleImg {
    height: 320px;
    width: 320px;
  }
  .as-expect {
    width: 300px;
  }
  .as-credit,
  .as-coupon {
    width: 200px;
    margin: -20px 60px;
  }
}

@media screen and (max-width: 1020px) {
  .as-header-svg {
    height: 600px;
  }
}

/* On screens that are 1024px wide or less */
@media screen and (max-width: 639px) {
  .as-circle-text h1,
  .as-circle-text h6 {
    text-align: left !important;
  }
}

/* On screens that are 1024px wide or less */
@media screen and (max-width: 959px) {
  .as-footer .uk-container {
    background-color: #101010;
  }
  .as-foot-svg,
  .as-auth {
    display: none !important;
  }
  .as-footer {
    margin-top: 0px !important;
  }
  .as-footer-section {
    padding-top: 0px !important;
  }
  .as-header-text {
    padding: 80px 80px 0 300px !important;
  }
  .uk-position-small[class*="uk-position-center-left"],
  .uk-position-small[class*="uk-position-center-right"] {
    padding: 0px;
  }
  .as-container-bg {
    position: relative;
  }
  .as-header nav {
    padding-top: 0px;
  }
  .as-header nav ul {
    padding-right: 0px;
  }
  .uk-navbar-left.uk-margin-xlarge-left {
    margin-left: 0 !important;
    text-align: left;
  }
  .as-logo {
    text-align: left !important;
    align-self: left;
  }
  .as-container {
    margin: 0 20px !important;
  }
  .as-qr .as-left-small {
    left: 0% !important;
  }
  .as-qr .as-right {
    right: 0% !important;
  }
  .as-header3 div {
    left: 0% !important;
  }
  .as-red-card {
    transform: scale(1);
  }
  .as-woman img {
    right: 0px;
  }
  .as-rounded-img {
    top: 20% !important;
  }
  .as-rounded-img2 {
    top: 21% !important;
  }
}

@media screen and (max-width: 890px) {
  .as-rounded-img2 {
    top: 350px !important;
  }
}

/* On screens that are 992px wide or less */
@media screen and (max-width: 800px) {
  .as-header3 div h2,
  .as-header3 div p {
    font-size: 12px;
    top: 20px;
  }
  .as-header3 div {
    top: 20px;
  }
  .as-benefit {
    margin-top: 0px !important;
  }
  .as-rounded-img {
    top: 18% !important;
  }
  .as-negative {
    padding-top: 120px !important;
  }
}

/* On screens that are 992px wide or less */
@media screen and (max-width: 750px) {
  .as-container {
    margin: auto 20px !important;
  }
  .as-container-margin {
    margin: auto 20px !important;
  }
}

/* On screens that are px wide or less */
@media screen and (max-width: 670px) {
  .as-header-text {
    padding: 80px 80px 0 180px !important;
  }
}

@media screen and (max-width: 639px) {
  * {
    text-align: center !important;
  }
  footer li,
  footer h4,
  .uk-offcanvas-bar li a,
  .uk-offcanvas-bar li,
  .as-list.uk-list-disc li {
    text-align: left !important;
  }
  .as-padding-right {
    padding: 20px;
  }
  .as-woman img {
    position: relative;
    top: -40px;
    right: 0px;
    margin: 0 auto;
  }
  .as-left {
    left: 0%;
  }
  .as-right {
    right: 0%;
  }
  .as-text-container h1 {
    text-align: center !important;
  }
  .as-header3 {
    background-image: url(../img/header3.png);
    min-height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .uk-list-disc > ::before {
    display: none;
  }
  .as-benefit > .uk-margin-xlarge-top,
  .as-benefit.uk-margin-xlarge-top,
  .as-intelligent,
  .as-money .uk-margin-xlarge-top {
    margin-top: 0px !important;
    padding-top: 0 !important;
  }
  .as-container-bg {
    padding-bottom: 30px;
  }
  .as-margin-top,
  .as-margin-top-2 {
    margin-top: 50px !important;
  }
  .as-margin-top-3 {
    margin-top: 30px !important;
  }
  .as-header-svg {
    height: 800px;
  }
  .as-phones {
    width: 50%;
  }
  .as-circle-height {
    min-height: 1400px !important;
  }
  .as-circleImg {
    margin: 0 auto !important;
  }
  .as-circle-cards {
    top: 0%;
    text-align: center;
  }
  .as-mapsP {
    left: 7% !important;
  }
  .as-rounded-img2 {
    top: 26% !important;
    left: 60%;
  }
  .as-negative {
    margin-top: -280px !important;
    padding-top: 50px !important;
  }
}

/* @media only screen and (min-width: 639px) {
  .as-rounded-img2 {
    background-color: red;
    /* top: 6000% !important; */
/*top: 600px !important;
    
  }
} */

@media screen and (max-width: 550px) {
  .as-header-text {
    padding: 20px !important;
    font-size: 14px;
    display: none;
  }
  .as-header-bg {
    height: 160px;
  }
  .as-overlay {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  .as-stores {
    display: none;
  }
  .as-header-auth .as-bg-gradient div.uk-padding {
    padding: 10px 0 !important;
  }
  .as-margin-top-4 {
    margin-top: 400px !important;
  }
  .as-rounded-img2 {
    top: 50% !important;
    left: 60%;
  }
}

@media screen and (min-width: 550px) {
  .as-header-bg {
    min-height: 1600px;
  }
}

@media screen and (max-width: 400px) {
  .as-bg-img-1 {
    height: 300px;
  }
  .uk-margin-large-top {
    margin-top: 10px !important;
  }
  .as-rounded-img2 {
    top: 50% !important;
    left: 50%;
  }
  .as-negative {
    margin-top: 20px !important;
  }
}

@media screen and (min-width: 300px) {
  .as-negative {
    margin-top: -200px !important;
  }
}

@media screen and (max-width: 300px) {
  .as-negative {
    margin-top: -150px !important;
  }
}
