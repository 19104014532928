@import url("https://fonts.googleapis.com/css2?family=Livvic:wght@100;300;400;700&display=swap");

/*
* Gcerevision Template

*/
/* ------------------------------------- */
/* TABLE OF CONTENTS
/* -------------------------------------
  1. IMPORT GOOGLE FONTS
  2. GENERAL
    -2.1 SECTION TITLE
    -2.2 BUTTONS
    -2.3 SOCIAL ICON
    -2.4 PRE LOADER
    -2.5 SCROLL TO UP
  3. NAVBAR
  4. INTRO SECTION
  4. HERO SECTION
  5. RESTAURANT SECTION
  6. DELIVERY SECTION
  7. FOOTER SECTION
  ------------------------------------- */
/* ==========================================================================
   1. IMPORT GOOGLE FONTS
   ========================================================================== */
* {
  font-family: "Livvic", sans-serif !important;
}
@font-face {
  font-family: "Quantify Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Quantify Regular"), url("../font/Quantify.woff") format("woff");
}

@font-face {
  font-family: "Quantify Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Quantify Bold"), url("../font/QuantifyBold.woff") format("woff");
}

.as-quantity-font {
  font-family: "Quantify Regular", sans-serif !important;
}

p,
h1,
h2,
h3 {
  font-family: "Livvic", sans-serif !important;
}

body {
  overflow-x: hidden;
}

.as-red {
  color: #ef3964 !important;
}

.as-blue {
  color: #0a2463 !important;
}

.as-white {
  color: #fff !important;
}

.as-grey {
  color: #6d7783 !important;
}

.as-grey3 {
  color: #a1a4ac;
}

.as-bg-red {
  background-color: #ef3964 !important;
}

.as-bg-white {
  background-color: #fff !important;
}

.as-red-1 {
  color: #e2274a !important;
}

.as-bg-greylight {
  color: #f7f7f7 !important;
}

.as-font-12 {
  font-size: 11.5pt !important;
}

.as-logo {
  font-size: 30px !important;
  padding-left: 5px;
}

.as-logo-img {
  width: 20px;
  height: 29px;
}

.as-header-bg {
  min-height: 760px !important;
  background-image: url(../img/header12.png) !important;
  background-size: cover !important;
}

.as-header {
  width: 100%;

  padding-top: 10px;
}

.as-header-auth {
  background-color: #fff !important;
  width: 100%;
}

.as-nav-gray {
  color: #999 !important;
}

.as-header ul li > a {
  text-transform: capitalize !important;
  color: white !important;
  font-size: 14px;
}

.as-header-auth ul li > a {
  text-transform: capitalize !important;

  font-size: 14px;
}
.as-header ul li > a.as-nav-gray {
  text-transform: capitalize !important;
  color: #999 !important;

  font-size: 14px;
}

.as-header nav div.uk-dropdown ul li > a {
  text-transform: capitalize !important;
  color: #333 !important;
}

.as-header nav ul li {
  padding-right: 10px;
}

/* .as-header nav ul {
  padding-right: 70px;
} */

.as-margin-small-top {
  margin-top: 3px;
}

.as-button-padding {
  padding: 10px 40px;
  background-color: #fb2a49 !important;
  border-radius: 30px;
}

.as-button-padding-white {
  padding: 10px 40px;
  background-color: #fff !important;
  border-radius: 30px;
}

.as-left {
  position: relative;
  left: 10%;
}

.as-left-small {
  position: relative;
  left: 5%;
}

.as-right {
  position: relative;
  right: 10%;
}

.as-right-small {
  position: relative;
  right: 5%;
}

.as-bg-img-1 {
  min-height: 600px;
}
.as-card-sign {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.as-padding-right {
  padding-right: 200px;
}

.as-margin-right {
  margin-right: 100px;
}

.as-header-text {
  padding: 0px 200px 0 500px !important;
  font-size: 16px;
}

.as-red-card {
  transform: scale(1.1);
  z-index: 1000;
}

.as-stores {
  margin: 120px auto auto 100px !important;
}

.as-woman {
  background-color: #f7f7f7;
}

.as-woman img {
  position: relative;
  top: -40px;
  right: 100px;
}

.as-auth {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.as-container-bg {
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 120px;
  margin-top: 100px;
  background-image: url(../img/curve.svg);
}

.as-container {
  margin: auto 100px !important;
}

.as-container-margin {
  margin: auto 100px !important;
}

.as-card {
  color: #a1a4ac;
  font-size: 12px;
  font-family: "Livvic", sans-serif !important;
}

.as-card-padding {
  padding: 20px;
}

.uk-position-small[class*="uk-position-center-left"],
.uk-position-small[class*="uk-position-center-right"] {
  padding: 15px 20px;
}

.as-border-red-top {
  border-top: 2px solid #df294c;
}

.as-border-radius-curve {
  border-radius: 50px;
}

.as-bg-gradient {
  width: 100%;
  min-height: 700px !important;
  height: 100%;

  background: linear-gradient(135deg, #f92a49 0%, #7a2757 100%);
}

.as-bg-height {
  min-height: 400px;
}

form input,
select {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

select {
  background-image: url(../img/chevron-down.png) !important;
  background-repeat: no-repeat !important;
  border-bottom: 1px solid #e5e5e5 !important;

  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  -o-appearance: none !important;
  appearance: none !important;
  background-position: 95% 50% !important;
}

select:after {
  background-repeat: no-repeat !important;
  content: "" !important;
  position: relative !important;
  -webkit-top: 12px !important;
  -moz-top: 12px !important;
  -o-top: 12px !important;
  top: 12px !important;
  right: 100px !important;
  padding-left: 40px !important;
  pointer-events: none !important;
}

.uk-tab > .uk-active > a {
  border-color: #df294c;
}

.uk-tab > * > a {
  border-bottom: 3px solid transparent;
}

.as-header3 {
  background-image: url(../img/header3.png);
  min-height: 500px;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  top: 80px !important;
}

.as-header3 div {
  top: 70px;
  position: relative;
  left: 22% !important;
}

.as-header-img {
  transform: scaleX(1.1);
  position: relative;
  top: 80px;
  right: 30px;
}

.as-img-div img {
  overflow: hidden !important;
}

.as-tips {
  padding: 10px 15px;
  font-weight: bold;
}

#rect1 {
  fill: url(#Gradient2);
}
.stop1 {
  stop-color: red;
}
.stop2 {
  stop-color: black;
  stop-opacity: 0;
}
.stop3 {
  stop-color: blue;
}

.as-padding-card {
  padding: 12px;
}

.as-bg-redT {
  background-color: rgba(235, 50, 84, 0.4);
}

.as-stepper {
  font-size: 30px;
  font-weight: bold;
  position: relative !important;
  top: -12px !important;
}

.as-phones {
  width: 80%;
}

.as-top {
  position: relative !important;
  top: 150px !important;
}

.as-circleImg {
  height: 450px;
  width: 450px;
  position: relative !important;
  text-align: center !important;
}

.as-expect {
  position: absolute !important;
  z-index: 5;
  top: -25%;
  left: 6%;
  width: 400px;
}

.as-credit {
  position: absolute !important;
  z-index: 5;
  margin: -80px 50px;
  width: 350px;
}

.as-intel {
  height: 100px;
  padding: 12px 7px 12px 10px;
  width: 100px;
  border-radius: 50px;
  transition: all 0.6s ease;
  background-color: #e0dfdf;
}

.as-intel:hover {
  background-color: #df294c;
  /* filter: invert(1) sepia(1) saturate(0) hue-rotate(75deg); */
}

.as-intel img {
  fill: red !important;
  filter: invert(1) sepia(1) saturate(0) hue-rotate(75deg);
}

.as-active {
  background-color: #df294c;
}

.as-maps {
  z-index: 5;
  top: -10%;
  left: 2%;
}

.as-maps2 {
  z-index: 5;
  top: -10%;
  right: 5%;
}

.as-ce {
  top: 15%;
  left: 2%;
}

.as-coupon {
  position: absolute !important;
  z-index: 5;
  margin: -60px 70px;
  width: 300px;
}

.as-circle-text {
  text-align: left !important;
}

.as-circle-text h1,
.as-circle-text h6 {
  text-align: left !important;
}

.as-circle {
  clip-path: circle(50%);
  background: linear-gradient(196.45deg, #0a2463 18.47%, #fb2a49 128.54%);
  height: 100%;
  width: 100%;
}

/* .test {
  background: linear-gradient(196.45deg, #0A2463 18.47%, #FB2A49 128.54%);
} */

.as-margin-top {
  margin-top: 300px !important;
}

.as-margin-top-2 {
  margin-top: 200px !important;
}

.as-margin-top-3 {
  margin-top: 100px !important;
}

.as-margin-top-4 {
  margin-top: 250px !important;
}

.as-rounded-img {
  position: absolute;
  border: 10px solid #fff;
  border-radius: 50%;
  z-index: 100;
  top: 600px !important;
}

.as-mapsP {
  top: -48% !important;
}

.as-rounded-img2 {
  border: 10px solid #fff;
  position: absolute;
  left: 30%;
  top: 600px !important;
}

.test {
  box-shadow: 25px 25px #ef3864;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -moz-box-shadow: 25px 25px #ef3864;
  -webkit-box-shadow: 25px 25px #ef3864;
}

.as-negative {
  margin-top: -200px !important;
}

.as-header-pos {
  right: 0 !important;
  top: 80px;
}

.as-navbar {
  top: 0;
  background-color: white !important;
  height: 80px !important;
  z-index: 1000;
}

.circle {
  height: 100%;
  width: 100%;
  background: linear-gradient(196.45deg, #0a2463 18.47%, #fb2a49 128.54%);
  border-radius: 50%;
}

.as-manPhone {
  top: 25%;
  right: 12% !important;
  /* box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16); */
}

.uk-divider-vertical {
  min-height: 250px;
}

.as-player {
  top: 35%;
  left: 40%;
}

.as-header-svg {
  height: 800px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -100;
}

.as-footer-svg {
  height: 650px;
  overflow: hidden;
}

.as-svg-foot {
  height: 100%;
  width: 100%;
}

.as-circle-cards {
  top: -130%;
  text-align: center;
}

figure {
  width: 52%;
  min-height: 600px;
  position: relative;
  background-color: red;
  overflow: hidden;
  z-index: -100;
}
video {
  object-fit: cover;
  position: absolute;
  top: 80px !important;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #df294c;
  clip-path: polygon(100% 0, 86% 75%, 0 95%, 0 0);
  z-index: -100;
}

.container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  -webkit-mask-image: url(#mask);
  mask-image: url(#mask);
}

.as-z-index-minus {
  z-index: -100 !important;
}

figcaption {
  position: absolute;
  background-color: #df294c;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  text-align: center;
  top: 50%;
  left: 40%;
  font-size: 3vw;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: 50%;
  z-index: 2;
}

/***************** old style *****************/

.uk-text-bold {
  font-weight: 700 !important;
}
.section-primary {
  background-color: #000;
}

.color-is-darkBlue {
  color: #032e59;
}

.color-is-white {
  color: #fff !important;
}

.color-is-gray-footer {
  color: #797878 !important;
}
.background-is-black {
  background-color: #000000d6 !important;
}

.as-contact {
  padding: 8px 5px 5px 5px !important;
  font-size: 13px;
}

.as-dropdown {
  padding: 10px !important;
  margin-top: 0 !important;
  position: absolute;
  left: 20% !important;
  min-width: 100px !important;
}

.uk-overlay-primary {
  background: rgba(34, 34, 34, 0.7) !important;
}
.uk-button-danger {
  background-color: #f50021 !important;
}
.background-section-1 * {
  background-color: #191a1c !important;
  color: #fff !important;
}
.background-section-2 * {
  background-color: #d83030;
  color: #fff !important;
}
.background-section-3 {
  background-color: #fff;
}

.as-app {
  margin-bottom: -170px !important;
  position: relative;
  margin-top: 0px;
}

.as-footer {
  position: absolute;
  margin-top: 170px;
  width: 100%;
}
.as-footer-section {
  padding-top: 50px;
}

.as-footer .uk-container {
  padding-top: 80px;
}

footer {
  font-size: 12px !important;
}
.wad-dropdown {
  min-width: 80px;
  border-radius: 8px;
}
